import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Translation } from "../App/reducer";
import { predicateComponentUnseenTooltip } from "../Common/tooltips";
import { definedNotNull } from "../Utils/functional";
import { assertUnreachable } from "../Utils/typeHelpers";
import * as gqltypes from "../gqltypes";
import { predicateComponentVisible } from "./components/CreateForm";

export const SYMBOLS = {
  PARTIALLY_SIGNED: "⚠️",
  UNANSWERED: "➖",
  REDACTED: "🚫",
  ADMIN_ANSWER: "🏫",
};

export const defaultQuestionType = gqltypes.FormQuestionType.multiChoice;

type availableTranslationLanguageType = "en" | "sv" | "nb" | "de";
export type availableTranslationLanguages = Pick<
  typeof gqltypes.ISO6391Language,
  availableTranslationLanguageType
>;

export enum availableTranslationLanguageEnum {
  en = gqltypes.ISO6391Language.en,
  sv = gqltypes.ISO6391Language.sv,
  nb = gqltypes.ISO6391Language.nb,
  nn = gqltypes.ISO6391Language.nn,
}

// TODO
// Each case in the switch returns the same object, but the typing isn't satisfied
// if the cases are collapsed into one group. Is there a better way to write this while
// keeping the typing correct?
function makeOptionQuestion(
  type:
    | gqltypes.FormQuestionType.checkboxes
    | gqltypes.FormQuestionType.multiChoice
    | gqltypes.FormQuestionType.ranking,
  question: string,
  id: string,
  shortName?: string | null,
  compulsory?: boolean,
  optionData?: gqltypes.FormQuestionOptions[] | null
): gqltypes.FormQuestionWithOptionsUnion {
  const options = definedNotNull(optionData)
    ? optionData
    : [makeDefaultOption(1)];
  switch (type) {
    case gqltypes.FormQuestionType.multiChoice:
      return {
        id,
        type,
        question,
        options,
        shortName,
        compulsory: compulsory || false,
      };
    case gqltypes.FormQuestionType.checkboxes:
      return {
        id,
        type,
        question,
        options,
        shortName,
        compulsory: false, // Should always be false
      };
    case gqltypes.FormQuestionType.ranking:
      return {
        id,
        type,
        question,
        options,
        shortName,
        compulsory: compulsory || false,
      };
    default:
      assertUnreachable(type);
      throw new Error(
        "makeOptionQuestion atempted to make an unknown option type"
      );
  }
}

export function makeQuestion(args: {
  type: gqltypes.FormQuestionType;
  shortName?: string | null;
  customOrder?: number;
  question?: string;
  id?: string;
  compulsory?: boolean;
  options?: gqltypes.FormQuestionOptions[] | null;
}): gqltypes.FormQuestionInput {
  const question = args.question || "";
  const id = args.id || uuidv4();
  const type = args.type;
  const shortName = args.shortName;
  switch (type) {
    case gqltypes.FormQuestionType.multiChoice:
    case gqltypes.FormQuestionType.checkboxes:
    case gqltypes.FormQuestionType.ranking:
      return makeOptionQuestion(
        type,
        question,
        id,
        shortName,
        args.compulsory,
        args.options
      );
    case gqltypes.FormQuestionType.shortText:
      return {
        id,
        type,
        question,
        shortName,
        compulsory: args.compulsory || false,
      };
    case gqltypes.FormQuestionType.longText:
      return {
        id,
        type,
        question,
        shortName,
        compulsory: args.compulsory || false,
      };
    case gqltypes.FormQuestionType.yesOrNo:
      return {
        id,
        type,
        question,
        shortName,
        compulsory: args.compulsory || false,
        predicates: [],
      };
    case gqltypes.FormQuestionType.date:
      return {
        id,
        type,
        question,
        shortName,
        compulsory: args.compulsory || false,
      };
    default:
      assertUnreachable(type);
      throw new Error("makeQuestion atempted to make an unknown type");
  }
}

export function makeQuestionFromTemplate(
  templateQuestionId: string,
  args: {
    type: gqltypes.FormQuestionType;
    shortName?: string | null;
    customOrder?: number;
    question?: string;
    id: string;
    options?: gqltypes.FormQuestionOptions[] | null;
  }
): gqltypes.FormQuestionInput {
  return { ...makeQuestion(args), templateQuestionId };
}

export function getQuestionOptions(
  question: gqltypes.FormQuestionInput
): gqltypes.FormQuestionOptions[] | null {
  if (!question.options) {
    return null;
  }
  const options: gqltypes.FormQuestionOptions[] = [];
  for (const option of question.options) {
    options.push({
      id: option.id,
      label: option.label,
      externalId: option.externalId,
    });
  }

  return options;
}

export function getQuestionPredicates(
  question: gqltypes.FormQuestionInput
): gqltypes.FormQuestionPredicate[] | null {
  const predicates: gqltypes.FormQuestionPredicate[] = [];
  for (const pred of question.predicates || []) {
    predicates.push({ ...pred });
  }

  return predicates;
}

export function getValidationType(
  question: gqltypes.FormQuestionInput
): gqltypes.ShortTextValidationType | null {
  if (!question.validationType) {
    return null;
  }

  return question.validationType;
}

export function getComponentQuestions(component: {
  questions: gqltypes.FormQuestionInput[];
}): gqltypes.FormQuestionInput[] {
  const questions: gqltypes.FormQuestionInput[] = [];
  for (const q of component.questions) {
    questions.push({
      id: q.id,
      compulsory: q.compulsory,
      options: getQuestionOptions(q),
      predicates: getQuestionPredicates(q),
      question: q.question,
      shortName: q.shortName,
      templateQuestionId: q.templateQuestionId,
      type: q.type,
      validationType: getValidationType(q),
      minSelectedOptions: (q as gqltypes.FormQuestionRanking)
        .minSelectedOptions,
      maxSelectedOptions: (q as gqltypes.FormQuestionRanking)
        .maxSelectedOptions,
      dropdownView: (q as gqltypes.FormQuestionMultiChoice).dropdownView,
    });
  }

  return questions;
}

export function makeDefaultOption(
  order: number,
  option?: gqltypes.FormQuestionOptions
): gqltypes.FormQuestionOptions {
  return {
    id: uuidv4(),
    label: option ? option.label : "",
    externalId: option ? option.externalId : null,
  };
}

export function makeEmptyPredicateComponent(): gqltypes.FormPredicateComponent {
  return {
    id: uuidv4(),
    questions: [],
    sensitive: false,
    permission: gqltypes.FormComponentPermission.read_base,
  };
}

export function makeEmptyComponent(): gqltypes.FormComponent {
  return {
    id: uuidv4(),
    title: "",
    description: "",
    questions: [
      makeQuestion({
        type: gqltypes.FormQuestionType.multiChoice,
        customOrder: 1,
      }),
    ],
    sensitive: false,
    attachments: [],
    permission: gqltypes.FormComponentPermission.read_base,
  };
}

export function makeEmptyFeebackComponent(): gqltypes.FormComponent {
  return {
    id: uuidv4(),
    applicationFeedbackId: uuidv4(),
    title: "",
    description: "",
    questions: [],
    sensitive: false,
    attachments: [],
    permission: gqltypes.FormComponentPermission.read_base,
  };
}

export function getTemplateComponentInLanguage(
  template: Pick<
    gqltypes.Template,
    "language" | "componentData" | "translations"
  >,
  language: gqltypes.ISO6391Language,
  fallbacks: gqltypes.ISO6391Language[]
) {
  if (template.language === language) {
    return makeComponentFromTemplate(template.componentData);
  }
  const translation = template.translations.find(
    (tr) => tr.language === language
  );
  if (translation) {
    return applyTranslationOnComponent(template.componentData, translation);
  }

  for (const fallback of fallbacks) {
    const fallbackTranslation = template.translations.find(
      (tr) => tr.language === fallback
    );
    if (fallbackTranslation) {
      return applyTranslationOnComponent(
        template.componentData,
        fallbackTranslation,
        fallback
      );
    }
  }

  return makeComponentFromTemplate(template.componentData, template.language);
}

export function makeComponentFromTemplate(
  template: gqltypes.ComponentTemplate,
  overrideLanguage?: gqltypes.ISO6391Language | null
): Pick<
  gqltypes.FormComponent,
  | "id"
  | "title"
  | "description"
  | "questions"
  | "sensitive"
  | "permission"
  | "attachments"
  | "templateId"
> & { language: gqltypes.ISO6391Language | null } {
  return {
    templateId: template.id,
    id: template.id,
    title: template.title,
    description: template.description,
    questions: template.questions.map((q) => makeQuestionFromTemplate(q.id, q)),
    sensitive: template.sensitive,
    permission: template.permission,
    attachments: [],
    language: overrideLanguage || null,
  };
}

export function applyTranslationOnComponent(
  componentIn: gqltypes.ComponentTemplate,
  translation: gqltypes.TemplateTranslation,
  overrideLanguage?: gqltypes.ISO6391Language | null
) {
  const component = _.cloneDeep(componentIn);
  component.questions.forEach((q) => {
    const tq = translation.componentData.questions.find(
      (tqf) => tqf.id === q.id
    );
    if (!tq) {
      throw new Error("no tq");
    }
    q.question = tq.question;
    q.shortName = tq.shortName;

    if (questionTypeHasOptions(q)) {
      q.options.forEach((o) => {
        const option = tq.options!.find((to) => to.id === o.id);
        o.label = option!.label;
      });
    }
  });
  return {
    ...component,
    title: translation.componentData.title,
    description: translation.componentData.description,
    attachments: translation.componentData.attachments,
    language: overrideLanguage || null,
    templateId: translation.id,
  };
}

export function makeComponentFromTemplateTranslation(
  template: gqltypes.TemplateTranslation["componentData"],
  overrideLanguage?: gqltypes.ISO6391Language | null
) {
  return {
    templateId: template.id,
    id: template.id,
    language: overrideLanguage || null,
    title: template.title,
    description: template.description,
    questions: template.questions.map((q) => ({
      id: q.id,
      question: q.question,
      shortName: q.shortName,
      options: q.options,
    })),
    attachments: [],
  };
}

export function questionTypeHasOptions(
  data:
    | gqltypes.FormQuestionWithOptionsUnion
    | gqltypes.FormQuestionWithoutOptionsUnion
): data is gqltypes.FormQuestionWithOptionsUnion {
  switch (data.type) {
    case gqltypes.FormQuestionType.multiChoice:
    case gqltypes.FormQuestionType.checkboxes:
    case gqltypes.FormQuestionType.ranking:
      return true;
    case gqltypes.FormQuestionType.shortText:
    case gqltypes.FormQuestionType.longText:
    case gqltypes.FormQuestionType.yesOrNo:
    case gqltypes.FormQuestionType.date:
      return false;
    default:
      assertUnreachable(data.type);
      throw new Error("questionTypeHasOptions got unexpected type");
  }
}

export function displayAnswer(
  tr: Translation["tr"],
  question: gqltypes.FormQuestionWithOptionsUnion,
  answer: gqltypes.QuestionAnswerUnion,
  raw?: boolean
): string | string[] {
  switch (question.type) {
    case gqltypes.FormQuestionType.checkboxes: {
      const options = question.options
        .filter(
          (o) =>
            (answer as gqltypes.AnswerCheckboxes).checkboxes.indexOf(o.id) !==
            -1
        )
        .map((o) => o.label);

      return raw ? options : options.join(", ");
    }
    case gqltypes.FormQuestionType.multiChoice: {
      const selectedOption = question.options.find(
        (o) => o.id === (answer as gqltypes.AnswerMultiChoice).multiChoice
      );
      return selectedOption ? selectedOption.label : "-";
    }
    case gqltypes.FormQuestionType.ranking: {
      const rankings = (answer as gqltypes.AnswerRanking).rankings.map(
        (rank) => {
          const option = question.options.find((o) => o.id === rank.value);
          return option ? option.label : "???";
        }
      );

      return raw ? rankings : rankings.join(", ");
    }
    case gqltypes.FormQuestionType.longText:
      return (answer as gqltypes.AnswerLongText).longText;
    case gqltypes.FormQuestionType.shortText:
      return (answer as gqltypes.AnswerShortText).shortText;
    case gqltypes.FormQuestionType.yesOrNo: {
      const yesOrNo = (answer as gqltypes.AnswerYesOrNo).yesOrNo;
      return definedNotNull(yesOrNo)
        ? yesOrNo
          ? tr("formYes")
          : tr("formNo")
        : "";
    }
    case gqltypes.FormQuestionType.date:
      return (answer as gqltypes.AnswerDate).date || "";
    default:
      assertUnreachable(question.type);
      return "";
  }
}

export function getSelectedResponses(
  tr: Translation["tr"],
  components: gqltypes.FormComponent[],
  questions: {
    question: gqltypes.FormQuestionInput;
    component: any;
    pc?: gqltypes.FormPredicateComponent;
  }[],
  formResponse:
    | gqltypes.FormAnswer
    | gqltypes.OrgPublicationReport_publication_recipients_users_edges_responses_response,
  responseId?: string
): {
  key: string;
  value: string | string[] | JSX.Element;
  redacted: boolean | null | undefined;
}[] {
  if (!formResponse) {
    return questions.map(({ question: q }) => ({
      key: q.id,
      value: "",
      redacted: null,
    }));
  }

  const responses = _.flatten(
    formResponse.components.map((c) =>
      c.questions.map((q) => ({ ...q, redacted: c.redacted }))
    )
  );

  return questions.map(({ question: q, pc }) => {
    const questionResponse = responses.find((rq) => rq.questionId === q.id);

    const responseText =
      pc && !predicateComponentVisible(components, formResponse, pc)
        ? predicateComponentUnseenTooltip(tr, q.id + "_" + responseId)
        : questionResponse
        ? displayAnswer(
            tr,
            q as gqltypes.FormQuestionWithOptionsUnion,
            questionResponse
          )
        : "";
    return {
      key: q.id,
      value: responseText,
      redacted: questionResponse && questionResponse.redacted,
    };
  });
}

export function isTemplateComponent(
  component: any
): component is gqltypes.FormComponent & {
  template: gqltypes.ComponentTemplate;
} {
  return component && definedNotNull(component.templateId);
}
