import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import { QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  ISTContainer,
  Loading,
  TableSortOrder,
} from "../../Common";
import {
  applicationCustomComponentId,
  getShortTextAnswer,
} from "../../Common/components/ApplicationCustomComponent";
import { ApplicationStatusFilter } from "../../Common/components/ApplicationStatusFilter";
import { ClipboardCopyButton } from "../../Common/components/ClipboardCopyButton";
import { Time } from "../../Common/components/Time";
import {
  formComponentDataFragment,
  fullFormAnswerFragment,
} from "../../Common/fragments";
import {
  downloadTableAsCsv,
  getApplicationStatusLabel,
} from "../../Common/utils";
import * as fn from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getSettings } from "../../settings";
import { getSelectedResponses } from "../form";
import { links } from "../links";
import { ApplicationTable } from "./ApplicationTable";
import { allPredicateQuestionsAndComponents } from "./CreateForm";
import { PublicationApplicationMetaInfo } from "./PublicationMetaInfo";
import { useLocation, useNavigate, useParams } from "react-router";
import replyColumn from "./ReplyColumn";

const { regionSettings } = getSettings();
const { readableNationalId } = regionSettings;

const colspanTdTbl = [
  <th key={1} />,
  [<th key={1} />, <th key={2} />],
  [<th key={1} />, <th key={2} />, <th key={3} />],
  [<th key={1} />, <th key={2} />, <th key={3} />, <th key={4} />],
];

interface Props extends Translation, AdminContext {
  applications: DataValue<gqltypes.viewOrgApplicationReport> & QueryResult;
}

const ApplicationReport = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { tr } = props;
  const selectedAppId = params.applicationId;

  const setSelectedAppId = (id: string) => {
    navigate(links.admin.application.report(id), { replace: true });
  };

  if (!props.applications) return null;

  if (props.applications.loading) return <Loading />;

  const applications = props.applications.organisation!.applications;

  const selectedApplication = applications.find(
    (app) => app.id === selectedAppId
  );
  return (
    <React.Fragment>
      <h1>{tr("viewApplicationReportTitle")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">
        {tr("viewApplicationReportDescription")}
      </p>
      <ISTContainer header={tr("viewApplicationHeader")}>
        {!applications ? (
          <Loading />
        ) : !applications || applications.length === 0 ? (
          <div className="alert alert-info m-content">
            {tr("viewApplicationNoPublicationsAvailable")}
          </div>
        ) : (
          <ApplicationTable
            tr={tr}
            applications={applications}
            selectedAppId={selectedAppId}
            onSelectApp={setSelectedAppId}
            scrollToElementId="application-view"
          />
        )}
      </ISTContainer>
      {selectedApplication ? (
        <ViewApplicationDetails
          tr={tr}
          application={selectedApplication}
          adminContext={props.adminContext}
        />
      ) : null}
    </React.Fragment>
  );
};

type SignerOrSubject =
  | gqltypes.applicationReportDetails["application"]["responses"][0]["signatures"][0]["signer"]
  | gqltypes.applicationReportDetails["application"]["responses"][0]["subject"]["user"];

// const getAddr = (signer: typeof res.signatures[0]["signer"]) => {
const getAddr = (signer: SignerOrSubject) => {
  if (!signer) return "";

  const addr =
    signer!.addresses && signer!.addresses.length ? signer!.addresses[0] : null;
  if (!addr) return "";
  return `${addr.streetAddress}, ${addr.postalCode}, ${addr.locality}`;
};

const getEmail = (signer: SignerOrSubject) => {
  if (!signer) return "";

  const emails = signer.emails;
  if (!emails) return "";

  for (const email of emails) {
    if (email.preferredHome) return email.value;
  }
};

interface DetailProps extends AdminContext {
  tr: Translation["tr"];
  application: gqltypes.viewOrgApplicationAnswer["organisation"]["applications"][0];
}

const ViewApplicationDetails = (props: DetailProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState: {
    filter?: any;
    tableSort?: TableSortOrder[];
  } = (location.state as any) || {};

  const reportTable = React.useRef<any>();

  const [showReport, setShowReport] = React.useState(false);

  const [filter, setFilter] = React.useState<
    { value: gqltypes.ApplicationResponseStatus; label: string }[]
  >(locationState.filter || []);
  const [includeSubjectAddress, setIncludeSubjectAddress] =
    React.useState(false);
  const [includeSigners, setIncludeSigners] = React.useState(false);
  const [includeSignerAddress, setIncludeSignerAddress] = React.useState(false);
  const [includeSignerEmail, setIncludeSignerEmail] = React.useState(false);
  const { tr } = props;
  const [dataFetched, setDataFetched] = React.useState(false);

  return (
    <ISTContainer header={props.application.name} id="application-view">
      <section>
        <PublicationApplicationMetaInfo
          publication={props.application}
          fields={["created", "creator", "sendDate", "validFrom", "validTo"]}
        />
      </section>

      <div>
        <div className="p-content row">
          <div className="col-12 col-md-6">
            <ApplicationStatusFilter
              tr={tr}
              value={filter}
              onChange={(selected: any) => {
                setFilter(selected);
                navigate(location.pathname, {
                  state: { ...locationState, filter: selected },
                  replace: true,
                });
              }}
            />
          </div>
          <div className="col-md-6">
            <Checkbox
              id="includeSubectAddress"
              label={tr("applicationReportIncludeSubectAddress")}
              checked={includeSubjectAddress}
              onChange={(id, checked) => {
                setIncludeSubjectAddress(checked);
              }}
            />
          </div>
          <div className="col-md-6">
            <Checkbox
              id="includeSigners"
              label={tr("applicationReportIncludeSigners")}
              checked={includeSigners}
              onChange={(id, checked) => {
                setIncludeSigners(checked);
              }}
            />
          </div>
          <div className="col-md-6">
            <Checkbox
              id="includeSignerAddress"
              label={tr("applicationReportIncludeSignerAddress")}
              checked={includeSigners && includeSignerAddress}
              disabled={!includeSigners}
              onChange={(id, checked) => {
                setIncludeSignerAddress(checked);
              }}
            />
          </div>
          <div className="col-md-6">
            <Checkbox
              id="includeSignerEmail"
              label={tr("applicationReportIncludeSignerEmail")}
              checked={includeSigners && includeSignerEmail}
              disabled={!includeSigners}
              onChange={(id, checked) => {
                setIncludeSignerEmail(checked);
              }}
            />
          </div>
          {!dataFetched ? (
            <div className="col-12 mt-3">
              <Button
                label={tr("generateReport")}
                onClick={() => {
                  setShowReport(true);
                }}
                disabled={showReport}
              />
            </div>
          ) : (
            <div className="col-12 mt-3">
              <Button
                label={tr("downloadReport")}
                onClick={() =>
                  downloadTableAsCsv(
                    "report-table",
                    `${props.application.name}_export`
                  )
                }
              />{" "}
              <ClipboardCopyButton
                getElementToCopy={() => reportTable.current}
                tooltipText={tr("copyToClipboardTooltip")}
              />
            </div>
          )}
        </div>

        <Query<
          gqltypes.applicationReportDetails,
          gqltypes.applicationReportDetailsVariables
        >
          onCompleted={(data) => {
            if (data && data.application) {
              setDataFetched(true);
            }
          }}
          onError={() => {
            setDataFetched(true);
          }}
          query={gql`
            query applicationReportDetails(
              $applicationId: ID!
              $statusFilters: [ApplicationResponseStatus!]
              $context: Context
            ) {
              application(id: $applicationId, context: $context) {
                id
                name
                responses(statusFilters: $statusFilters) {
                  id
                  actions {
                    action
                    comment
                  }
                  feedbackId
                  feedbackDescription
                  creator {
                    id
                    name
                    source
                  }
                  subject {
                    userId
                    name
                    nationalId
                    user {
                      id
                      source
                      name
                      emails {
                        value
                        preferredHome
                      }
                      addresses {
                        source
                        type
                        streetAddress
                        locality
                        region
                        postalCode
                        country
                      }
                    }
                  }
                  created
                  modified
                  status
                  seenByOrg
                  response {
                    ...FullFormAnswer
                  }
                  signatures {
                    signer {
                      id
                      name
                      nationalId
                      emails {
                        value
                        preferredHome
                      }
                      addresses {
                        source
                        type
                        streetAddress
                        locality
                        region
                        postalCode
                        country
                      }
                    }
                  }
                }
                form {
                  id
                  name
                  componentData {
                    ...FormComponentData
                  }
                }
              }
            }
            ${formComponentDataFragment}
            ${fullFormAnswerFragment}
          `}
          variables={{
            applicationId: props.application.id,
            statusFilters: filter ? filter.map((f) => f.value) : [],
            context: props.adminContext,
          }}
          skip={!showReport}
        >
          {({ data, loading, error }) => {
            if (!showReport) return null;
            if (loading) return <Loading />;

            if (data && data.application && data.application.responses) {
              const form = data.application.form;
              const components = form.componentData.components;

              let signerColspan = 1;
              if (includeSignerAddress) signerColspan++;
              if (includeSignerEmail) signerColspan++;

              const signerTd = colspanTdTbl[signerColspan];

              const questions = fn.flattenAll(
                components.map((c) =>
                  c.questions.map((q) => {
                    const predQuestions = allPredicateQuestionsAndComponents(
                      form.componentData.predicateComponents || [],
                      q
                    ).map(({ question: qu, predicateComponent: pc }) => ({
                      component: c,
                      question: qu,
                      pc,
                    }));
                    return [{ component: c, question: q }, predQuestions];
                  })
                )
              );

              return (
                <div style={{ overflowX: "auto" }}>
                  <table id="report-table" className="table" ref={reportTable}>
                    <thead>
                      <tr>
                        <th>{tr("applicationReportColumnSubjectName")}</th>
                        <th>
                          {tr("applicationReportColumnSubjectNationalId")}
                        </th>

                        {includeSubjectAddress ? (
                          <th>{tr("applicationColumnReportAddress")}</th>
                        ) : null}

                        <th>{tr("applicationReportColumnSeenByOrg")}</th>
                        <th>{tr("applicationReportColumnStatus")}</th>
                        <th>{tr("applicationReportColumnReply")}</th>
                        <th>
                          {tr("applicationCustomComponentGuardianSource")}
                        </th>
                        <th>{tr("applicationReportColumnCreator")}</th>
                        {includeSigners ? (
                          <React.Fragment>
                            {[0, 1].map((index) => {
                              const signerAddressHeader: string = tr(
                                "applicationReportColumnSignerAddress"
                              );

                              const signerEmailHeader: string = tr(
                                "applicationReportColumnSignerEmail"
                              );

                              return (
                                <React.Fragment key={index}>
                                  <th>
                                    {tr("applicationReportColumnSigners")}
                                  </th>
                                  <th>
                                    {tr("applicationColumnReportNationalId")}
                                  </th>
                                  {includeSignerAddress ? (
                                    <th>{signerAddressHeader}</th>
                                  ) : null}
                                  {includeSignerEmail ? (
                                    <th>{signerEmailHeader}</th>
                                  ) : null}
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        ) : null}
                        {questions.map(
                          ({
                            component,
                            question,
                          }: {
                            component: gqltypes.FormComponent;
                            question: gqltypes.FormQuestion;
                          }) => (
                            <th key={question.id}>
                              {`${component.title}: ${
                                question.shortName || question.question
                              }`}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data.application.responses.map((res) => {
                        const customAppData =
                          res.response &&
                          res.response.components.find(
                            (c) =>
                              c.componentId === applicationCustomComponentId
                          );
                        if (!res.response || !customAppData) return null;
                        const subId = getShortTextAnswer(
                          customAppData,
                          "application_sub_id"
                        );

                        return (
                          <tr key={res.id}>
                            <td>{res.subject.name}</td>
                            <td>
                              {readableNationalId(res.subject.nationalId)}
                            </td>

                            {includeSubjectAddress ? (
                              <td>
                                {res.subject.user
                                  ? getAddr(res.subject.user)
                                  : null}
                              </td>
                            ) : null}
                            <td>
                              {<Time date={res.seenByOrg!} format="dateTime" />}
                            </td>
                            <td>{getApplicationStatusLabel(tr, res.status)}</td>
                            <td>
                              {replyColumn(
                                {
                                  feedbackId: res.feedbackId ?? undefined,
                                  feedbackDescription:
                                    res.feedbackDescription ?? undefined,
                                  actions: res.actions,
                                  status: res.status,
                                },
                                components,
                                tr
                              )}
                            </td>
                            <td>
                              {subId
                                ? subId === "other"
                                  ? tr(
                                      "applicationCustomComponentGuardianSourceManually"
                                    )
                                  : tr(
                                      "applicationCustomComponentGuardianSourceEducloud"
                                    )
                                : "???"}
                            </td>
                            <td>{res.creator!.name}</td>
                            {includeSigners ? (
                              res.signatures[0] ? (
                                <React.Fragment>
                                  <td>{res.signatures[0].signer!.name}</td>
                                  <td>
                                    {readableNationalId(
                                      res.signatures[0].signer!.nationalId || ""
                                    )}
                                  </td>
                                  {includeSignerAddress ? (
                                    <td>{getAddr(res.signatures[0].signer)}</td>
                                  ) : null}
                                  {includeSignerEmail ? (
                                    <td>
                                      {getEmail(res.signatures[0].signer)}
                                    </td>
                                  ) : null}
                                </React.Fragment>
                              ) : (
                                <td colSpan={signerColspan} />
                              )
                            ) : null}
                            {includeSigners ? (
                              res.signatures[1] ? (
                                <React.Fragment>
                                  <td>{res.signatures[1].signer!.name}</td>
                                  <td>
                                    {readableNationalId(
                                      res.signatures[1].signer!.nationalId || ""
                                    )}
                                  </td>
                                  {includeSignerAddress ? (
                                    <td>{getAddr(res.signatures[1].signer)}</td>
                                  ) : null}
                                  {includeSignerEmail ? (
                                    <td>
                                      {getEmail(res.signatures[1].signer)}
                                    </td>
                                  ) : null}
                                </React.Fragment>
                              ) : (
                                signerTd
                              )
                            ) : null}
                            {getSelectedResponses(
                              tr,
                              components,
                              questions,
                              res.response,
                              res.id
                            ).map(({ key, value }) => (
                              <td key={key}>{value}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            } else {
              return null;
            }
          }}
        </Query>
      </div>
    </ISTContainer>
  );
};

const orgAppQuery = gql`
  query viewOrgApplicationReport($context: Context!) {
    organisation(context: $context) {
      id
      applications(schoolunitSpecificIfNotOrgPermission: true) {
        id
        name
        form {
          id
          name
          subType
        }
        creator {
          id
          name
        }
        created
        modified
        sendDate
        singleGuardianSend
        singleGuardianAcceptOffer
        schoolunitSpecific
        sent
        closed
        stats {
          answered
          open
          closed
        }
      }
    }
  }
`;

const withApplications = graphql<
  Props,
  gqltypes.viewOrgApplicationReport,
  gqltypes.viewOrgApplicationReportVariables,
  any
>(orgAppQuery, {
  name: "applications",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

export const ApplicationReportContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withApplications
)(ApplicationReport);
