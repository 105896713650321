import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { QueryResult, useQuery } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { Link } from "react-router-dom";
import {
  useParams,
  useNavigate,
  NavigateFunction,
  Location,
  useLocation,
} from "react-router";
import {
  AdminContext,
  Translation,
  UserPermission,
  withAdminContext,
  withTranslation,
  withUserPermissions,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  ISTContainer,
  Loading,
  Table,
  TableSortOrder,
} from "../../Common";
import { ApplicationStatusFilter } from "../../Common/components/ApplicationStatusFilter";
import { Time } from "../../Common/components/Time";
import {
  formComponentDataFragment,
  fullFormTranslationFragment,
} from "../../Common/fragments";
import { getApplicationStatusLabel } from "../../Common/utils";
import { client, getAdminContext } from "../../api";
import * as gqltypes from "../../gqltypes";
import { getSettings } from "../../settings";
import { links } from "../links";
import { ActionContainer } from "./ApplicationResponse";
import { ApplicationTable } from "./ApplicationTable";
import { PublicationApplicationMetaInfo } from "./PublicationMetaInfo";
import { viewApplicationAnswerDetails_application_responses } from "../../gqltypes";
import { UncontrolledTooltip } from "reactstrap";
import { GenericError } from "../../Common/components/GenericError";
import replyColumn from "./ReplyColumn";

const config = getSettings();

interface Props extends Translation, AdminContext, UserPermission {
  applications: DataValue<gqltypes.viewOrgApplicationAnswer> & QueryResult;
}

const ViewApplicationAnswers = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedAppId = params.applicationId;

  const setSelectedAppId = (id: string) => {
    navigate(links.admin.application.answers(id));
  };

  if (!props.applications) return null;

  const { tr } = props;
  if (props.applications.loading) return <Loading />;

  const applications = props.applications.organisation!.applications;

  const selectedApplication = applications.find(
    (app) => app.id === selectedAppId
  );

  const hasPerformActionPermission =
    props.permissions && props.permissions.org.application_answers
      ? true
      : false;

  return (
    <React.Fragment>
      <h1>{tr("viewApplicationAnswersTitle")}</h1>
      <p className="col-12 col-md-9 p-0 pb-3">
        {tr("viewApplicationAnswersDescription")}
      </p>
      <ISTContainer header={tr("viewApplicationHeader")}>
        {!applications ? (
          <Loading />
        ) : !applications || applications.length === 0 ? (
          <div className="alert alert-info m-content">
            {tr("viewApplicationNoPublicationsAvailable")}
          </div>
        ) : (
          <ApplicationTable
            tr={tr}
            applications={applications}
            selectedAppId={selectedAppId}
            onSelectApp={setSelectedAppId}
            scrollToElementId="application-view"
          />
        )}
      </ISTContainer>
      {selectedApplication ? (
        <ViewApplicationDetails
          tr={tr}
          application={selectedApplication}
          navigate={navigate}
          location={location}
          hasPerformActionPermission={hasPerformActionPermission}
        />
      ) : null}
    </React.Fragment>
  );
};

interface DetailProps {
  tr: Translation["tr"];
  application: gqltypes.viewOrgApplicationAnswer["organisation"]["applications"][0];
  navigate: NavigateFunction;
  location: Location;
  hasPerformActionPermission: boolean;
}

const toggleSelected = (
  selectedResponses: Set<string>,
  setSelectedResponses: (set: Set<string>) => void,
  responses: gqltypes.viewApplicationAnswerDetails["application"]["responses"],
  setSelectedStatus: (
    type: gqltypes.ApplicationResponseStatus | undefined
  ) => void,
  id: string,
  checked: boolean
) => {
  const newSet = new Set(selectedResponses);
  const selected = responses.find((res) => res.id === id);

  if (checked) {
    newSet.add(id);
    if (selected) {
      setSelectedStatus(selected.status);
    }
  } else {
    newSet.delete(id);
    if (newSet.size === 0) {
      setSelectedStatus(undefined);
    }
  }
  setSelectedResponses(newSet);
};

const ViewApplicationDetails = (props: DetailProps) => {
  const locationState: { filter?: any; tableSort?: TableSortOrder[] } =
    (props.location.state as any) || {};

  const [filter, setFilter] = React.useState<
    { value: gqltypes.ApplicationResponseStatus; label: string }[]
  >(locationState.filter);
  const { tr } = props;

  const [selectedStatus, setSelectedStatus] =
    React.useState<gqltypes.ApplicationResponseStatus>();
  const [selectedResponses, setSelectedResponses] = React.useState(
    new Set<string>()
  );

  const { data, loading, error } = useQuery<
    gqltypes.viewApplicationAnswerDetails,
    gqltypes.viewApplicationAnswerDetailsVariables
  >(
    gql`
      query viewApplicationAnswerDetails($applicationId: ID!) {
        application(id: $applicationId) {
          id
          name
          schoolunitSpecific
          form {
            id
            type
            subType
            name
            description
            language
            componentData {
              ...FormComponentData
            }
            translations {
              ...FullTranslation
            }
            owner {
              id
              displayName
            }
          }
          responses {
            id
            actions {
              action
              comment
            }
            feedbackId
            feedbackDescription
            creator {
              id
              name
              source
              nationalId
              verifiedRelation {
                isVerifiedRelation
                customerId
              }
              tempNationalIds {
                org
                value
              }
              orgId
            }
            subject {
              userId
              name
              nationalId
              user {
                id
                name
                source
                customerId
                idp
                idpSub
              }
            }
            created
            modified
            status
            seenByOrg
            lastAction {
              id
              action
              newStatus
              comment
              created
            }
            schoolUnit {
              id
              displayName
            }
          }
        }
      }
      ${formComponentDataFragment}
      ${fullFormTranslationFragment}
    `,
    {
      variables: { applicationId: props.application.id },
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    setSelectedStatus(undefined);
    setSelectedResponses(new Set());
  }, [props.application.id]);

  if (error) return <GenericError />;

  const boundCheckboxHandle = toggleSelected.bind(
    null,
    selectedResponses,
    setSelectedResponses,
    data?.application.responses,
    setSelectedStatus
  );

  const EMPTY_NAME = "<Empty name>";

  if (!data || !data.application || !data.application.responses) {
    return <Loading />;
  }

  const components = data.application.form.componentData.components;

  return (
    <React.Fragment>
      <ISTContainer header={props.application.name} id="application-view">
        <section>
          <PublicationApplicationMetaInfo
            publication={props.application}
            fields={[
              "created",
              "modified",
              "creator",
              "sendDate",
              "validFrom",
              "validTo",
            ]}
          />
        </section>
        <div>
          <div className="p-content row">
            <div className="col-12 col-md-6">
              <ApplicationStatusFilter
                tr={tr}
                value={filter}
                onChange={(selected) => {
                  setFilter(selected);
                  props.navigate(props.location.pathname, {
                    state: { ...locationState, filter: selected },
                    replace: true,
                  });
                }}
              />
            </div>
            {selectedStatus && data ? (
              <React.Fragment>
                <div className="col-md-6 d-flex">
                  <Button
                    label={tr(
                      "viewApplicationAnswersMarkAllStatus",
                      getApplicationStatusLabel(tr, selectedStatus)
                    )}
                    onClick={() => {
                      const ids = data.application.responses
                        .filter((res) => res.status === selectedStatus)
                        .map((res) => res.id);
                      setSelectedResponses(new Set(ids));
                    }}
                  />{" "}
                  <Button
                    className="ml-auto"
                    label={tr("viewApplicationAnswersUnmarkAllResponses")}
                    level="secondary"
                    onClick={() => {
                      setSelectedResponses(new Set());
                      setSelectedStatus(undefined);
                    }}
                  />
                </div>
              </React.Fragment>
            ) : null}
          </div>
          {loading ? <Loading /> : null}
          {!loading && data ? (
            <Table
              sortChanged={(sortOrder: TableSortOrder[]) => {
                props.navigate(props.location.pathname, {
                  state: { ...locationState, tableSort: sortOrder },
                  replace: true,
                });
              }}
              initialOrder={
                locationState.tableSort
                  ? locationState.tableSort.map((ts) => ts.col)
                  : "sentIn"
              }
              initialOrderDir={
                locationState.tableSort
                  ? locationState.tableSort.map((ts) => ts.dir)
                  : undefined
              }
              headers={[
                {
                  key: "checkbox",
                  element: null,
                  unsortable: true,
                  hidden:
                    !props.hasPerformActionPermission ||
                    props.application.form.subType ===
                      gqltypes.FormSubType.outMigration,
                },
                {
                  key: "subject",
                  element: tr("viewApplicationAnswersColumnSubject"),
                  usingSortValue: true,
                },
                {
                  key: "creator",
                  element: tr("viewApplicationAnswersColumnCreator"),
                  usingSortValue: true,
                },
                {
                  key: "status",
                  element: tr("viewApplicationAnswersColumnStatus"),
                },
                {
                  key: "reply",
                  element: tr("applicationViewAnswerColumnReply"),
                },
                {
                  key: "sentIn",
                  element: tr("viewApplicationAnswersColumnSentIn"),
                  usingSortValue: true,
                },
                {
                  key: "schoolunit",
                  usingSortValue: true,
                  hidden: !data.application.schoolunitSpecific,
                  element: tr("viewApplicationAnswersColumnSchoolunit"),
                },
                { element: null, key: "show_answer", unsortable: true },
              ]}
              rows={data.application.responses.map((res, index) => ({
                key: res.id,
                hidden:
                  filter && filter.length
                    ? !filter.some((f) => f.value === res.status)
                    : false,
                columns: {
                  checkbox: {
                    content: (
                      <Checkbox
                        id={res.id}
                        checked={selectedResponses.has(res.id)}
                        onChange={boundCheckboxHandle}
                        disabled={
                          selectedStatus && selectedStatus !== res.status
                        }
                      />
                    ),
                  },
                  subject: {
                    content: (
                      <>
                        {res.subject.name && res.subject.name !== EMPTY_NAME ? (
                          res.subject.nationalId ? (
                            <>
                              {isCreatorVerifiedComponent(
                                res,
                                tr,
                                `${res.subject.userId}_${index}`
                              )}
                              {res.subject.name} (
                              {config.regionSettings.readableNationalId(
                                res.subject.nationalId
                              )}
                              )
                            </>
                          ) : (
                            <>
                              {isCreatorVerifiedComponent(
                                res,
                                tr,
                                `${res.subject.userId}_${index}`
                              )}
                              {res.subject.name}
                            </>
                          )
                        ) : res.subject.nationalId ? (
                          <>
                            {isCreatorVerifiedComponent(
                              res,
                              tr,
                              `${res.subject.userId}_${index}`
                            )}
                            <span id={`key_${res.id}_${index}`}>
                              {config.regionSettings.readableNationalId(
                                res.subject.nationalId
                              )}
                              <UncontrolledTooltip
                                target={`key_${res.id}_${index}`}
                              >
                                {tr("viewApplicationCreatorNameMissing")}
                              </UncontrolledTooltip>
                            </span>
                          </>
                        ) : (
                          <>
                            {isCreatorVerifiedComponent(
                              res,
                              tr,
                              `${res.subject.userId}_${index}`
                            )}
                            <span id={`key_${res.id}_${index}`}>
                              {tr("viewApplicationAnswersSubjectMissing")}
                              <UncontrolledTooltip
                                target={`key_${res.id}_${index}`}
                              >
                                {tr(
                                  "viewApplicationCreatorNameAndCivicMissing"
                                )}
                              </UncontrolledTooltip>
                            </span>
                          </>
                        )}
                      </>
                    ),
                    sortValue: res.subject.name,
                  },
                  creator: {
                    content: res.creator ? (
                      res.creator.name === EMPTY_NAME ||
                      res.creator.name.trim().length === 0 ? (
                        res.creator.nationalId ||
                        (res.creator.tempNationalIds &&
                          res.creator.tempNationalIds.length > 0) ? (
                          <>
                            {isCreatorVerifiedComponent(
                              res,
                              tr,
                              `${res.creator.id}_${index}`
                            )}
                            {getReadableNatIdOrTempId(res.creator, index, tr)}
                          </>
                        ) : (
                          <>
                            {isCreatorVerifiedComponent(
                              res,
                              tr,
                              `${res.creator.id}_${index}`
                            )}
                            <span id={`key_${res.creator.id}_${index}`}>
                              {tr("viewApplicationCreatorNameMissingLabel")}
                              <UncontrolledTooltip
                                target={`key_${res.creator.id}_${index}`}
                              >
                                {tr(
                                  "viewApplicationCreatorNameAndCivicMissing"
                                )}
                              </UncontrolledTooltip>
                            </span>
                          </>
                        )
                      ) : (
                        <>
                          {isCreatorVerifiedComponent(
                            res,
                            tr,
                            `${res.creator.id}_${index}`
                          )}
                          {res.creator.name}
                        </>
                      )
                    ) : (
                      "??"
                    ),
                  },
                  status: {
                    content: getApplicationStatusLabel(tr, res.status),
                  },
                  reply: {
                    content: replyColumn(
                      {
                        feedbackId: res.feedbackId ?? undefined,
                        feedbackDescription:
                          res.feedbackDescription ?? undefined,
                        actions: res.actions,
                        status: res.status,
                      },
                      components,
                      tr
                    ),
                  },
                  sentIn: {
                    content: <Time date={res.seenByOrg!} />,
                    sortValue: res.seenByOrg,
                  },           
                  schoolunit: {
                    content: res.schoolUnit ? (
                      res.schoolUnit.displayName
                    ) : (
                      <span className="text-muted">
                        {tr("viewApplicationAnswersSchoolunitNotAssigned")}
                      </span>
                    ),
                    sortValue: res.schoolUnit
                      ? res.schoolUnit.displayName
                      : "-",
                  },
                  show_answer: {
                    content: res ? (
                      <Link
                        title={tr("viewAnswerShowForm")}
                        aria-label={tr(
                          "viewAnswerAriaShowForm",
                          res.subject.name
                        )}
                        to={links.admin.application.response(res.id)}
                      >
                        <i
                          aria-hidden="true"
                          className="fas fa-chevron-circle-right"
                        />
                      </Link>
                    ) : null,
                  },
                },
              }))}
            />
          ) : null}
        </div>
      </ISTContainer>

      {selectedStatus && data ? (
        <div className="col-12 my-content">
          <ActionContainer
            tr={tr}
            form={data.application.form}
            status={selectedStatus}
            perform={(action, comment, extras) => {
              const selected = data.application.responses.filter((res) =>
                selectedResponses.has(res.id)
              );
              performMultiAction(
                selected.map((s) => ({
                  responseId: s.id,
                  previousEventId: s.lastAction.id,
                })),
                action,
                comment,
                extras
              );
              setSelectedStatus(undefined);
              setSelectedResponses(new Set());
            }}
            multiEditMode
            numberOfEdits={selectedResponses.size}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

type PerformActionExtras = {
  feedbackId?: string;
  feedbackDescription?: string;
};

const performMultiAction = (
  responses: {
    responseId: string;
    previousEventId: string;
  }[],
  action: gqltypes.ApplicationResponseAction,
  comment: string,
  extras?: PerformActionExtras
) => {
  const context = getAdminContext();

  client.mutate<
    gqltypes.AdminPerformMultiApplicationResponseActions,
    gqltypes.AdminPerformMultiApplicationResponseActionsVariables
  >({
    mutation: gql`
      mutation AdminPerformMultiApplicationResponseActions(
        $input: MultiActionInput!
        $context: Context!
      ) {
        performMultiApplicationResponseActions(
          input: $input
          context: $context
        ) {
          id
          creator {
            id
            name
            source
          }
          subject {
            userId
            name
            user {
              id
              source
              customerId
              name
            }
          }
          created
          modified
          status
          seenByOrg
          lastAction {
            id
            action
            newStatus
            comment
            created
          }
          schoolUnit {
            id
            displayName
          }
        }
      }
    `,
    variables: {
      context,
      input: {
        responses,
        action,
        comment,
        ...extras,
      },
    },
  });
};

const orgAppQuery = gql`
  query viewOrgApplicationAnswer($context: Context!) {
    organisation(context: $context) {
      id
      applications(schoolunitSpecificIfNotOrgPermission: true) {
        id
        name
        closed
        form {
          id
          name
          subType
        }
        creator {
          id
          name
        }
        created
        modified
        sendDate
        singleGuardianSend
        singleGuardianAcceptOffer
        schoolunitSpecific
        sent
        stats {
          answered
          open
          closed
        }
      }
    }
  }
`;

const withApplications = graphql<
  Props,
  gqltypes.viewOrgApplicationAnswer,
  gqltypes.viewOrgApplicationAnswerVariables,
  any
>(orgAppQuery, {
  name: "applications",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

export const ViewApplicationAnswersContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withApplications,
  withUserPermissions
)(ViewApplicationAnswers);

export function isCreatorVerifiedComponent(
  res: viewApplicationAnswerDetails_application_responses,
  tr: Translation["tr"],
  identifier: string
) {
  const creator: gqltypes.viewApplicationAnswerDetails_application_responses_creator =
    res.creator;

  if (!creator) {
    return null;
  }

  const { isVerifiedRelation, customerId } = creator.verifiedRelation;

  if (isVerifiedRelation === true) {
    return "";
  } else if (isVerifiedRelation === false && customerId === null) {
    return (
      <>
        <span
          id={`tooltipViewApplicationAnswersCreator_${identifier}`}
          data-toggle="tooltip"
          style={{
            fontSize: "12px",
            maxWidth: "90px",
            minWidth: "90px",
          }}
          className="text-truncate"
        >
          <i
            className="fas fa-exclamation-triangle text-warning m-1"
            style={{
              color: "var(--yellow)",
            }}
          />
        </span>
        <UncontrolledTooltip
          target={`tooltipViewApplicationAnswersCreator_${identifier}`}
        >
          {tr("toolTipCreatorHasNoRelationToSubject")}
        </UncontrolledTooltip>
      </>
    );
  } else {
    return (
      <>
        <span
          id={`tooltipViewApplicationAnswersCreator_${identifier}`}
          data-toggle="tooltip"
          style={{
            fontSize: "12px",
            maxWidth: "90px",
            minWidth: "90px",
          }}
          className="text-truncate"
        >
          <i
            className="fas fa-exclamation-triangle text-warning m-1"
            style={{
              color: "var(--yellow)",
            }}
          />
        </span>
        <UncontrolledTooltip
          target={`tooltipViewApplicationAnswersCreator_${identifier}`}
        >
          {tr("toolTipCreatorHasNoRelationToSubjectWithCustomerId", customerId)}
        </UncontrolledTooltip>
      </>
    );
  }
}

export function getReadableNatIdOrTempId(
  creator: gqltypes.viewApplicationAnswerDetails_application_responses_creator,
  index: number,
  tr: Translation["tr"]
) {
  if (!creator.nationalId && !creator.tempNationalIds) {
    return (
      <span id={`key_${creator.id}${index}`}>
        <UncontrolledTooltip target={`key_${creator.id}${index}`} delay={0}>
          {tr("viewApplicationCreatorNameMissing")}
        </UncontrolledTooltip>
      </span>
    );
  }

  if (creator.nationalId) {
    return (
      <span id={`key_${creator.id}${index}`}>
        {config.regionSettings.readableNationalId(creator.nationalId)}
        <UncontrolledTooltip target={`key_${creator.id}${index}`} delay={0}>
          {tr("viewApplicationCreatorNameMissing")}
        </UncontrolledTooltip>
      </span>
    );
  }

  if (creator.tempNationalIds && creator.tempNationalIds.length > 0) {
    return (
      <>
        <span id={`key_${creator.id}${index}`}>
          {config.regionSettings.readableNationalId(
            creator.tempNationalIds[0].value
          )}
          <UncontrolledTooltip target={`key_${creator.id}${index}`} delay={0}>
            {tr("viewApplicationCreatorNameMissing")}
          </UncontrolledTooltip>
        </span>
        <span
          id={`tooltipViewApplicationAnswersTempNationalId_${creator.tempNationalIds[0].value}_${index}`}
          data-toggle="tooltip"
        >
          <i
            className="fas fa-exclamation-triangle text-warning m-1"
            style={{
              color: "var(--yellow)",
            }}
          />
          <UncontrolledTooltip
            target={`tooltipViewApplicationAnswersTempNationalId_${creator.tempNationalIds[0].value}_${index}`}
          >
            {tr("tooltipViewApplicationAnswerTempNationalIdOrg")}{" "}
            {creator.tempNationalIds[0].org}
          </UncontrolledTooltip>
        </span>
      </>
    );
  }
}
